import React, { ChangeEvent, FC } from 'react'
import classnames from 'classnames'
import Tooltip from 'components/Tooltip'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  label?: string
  name: string
  placeholder: string
  value: any
  tooltip?: string
  required?: boolean
  error?: boolean
  errorText?: string
  className?: string
  disabled?: boolean
  withoutMargin?: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

const Textarea: FC<IProps> = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  tooltip,
  required,
  error,
  errorText,
  disabled,
  className,
  withoutMargin,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={classnames('input-wrapper', {
        'input-wrapper--error': error,
        'input-wrapper--without-margin': withoutMargin,
        'margin-top-small': !withoutMargin,
      })}
    >
      {label && (
        <label className="label">
          {label}&nbsp;
          {required && <span className="paragraph--orange">*</span>}
          <Tooltip content={tooltip} />
        </label>
      )}

      <textarea
        className={classnames('textarea', {
          'textarea--error': error,
          [className]: className,
        })}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />

      {error && (
        <p className="input__error-text input__error-text--visible">
          {errorText || t('common.requiredField')}
        </p>
      )}
    </div>
  )
}

export default Textarea
